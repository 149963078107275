import { useState } from 'react';
import { Empty } from 'antd';

import { PlusButton, CollapsibleBlock } from 'components/atomic';
import { TemplateFieldData, Types } from '../../types';
import { FieldsForm } from './FieldsForm';
import { FieldsList } from './FieldsList';
import { FieldsState } from './types';
import { getNewId } from '../helpers';

export const FieldsContainer = ({ title, isTable, fields, allFields, setFields, types }: Props) => {
    const [showForm, setShowForm] = useState(!fields.length);
    const [selectedField, setSelectedField] = useState<TemplateFieldData | undefined>();

    const handleAddClick = () => {
        setSelectedField(undefined);
        setShowForm(true);
    };

    const handleEditClick = (selected: TemplateFieldData) => {
        setSelectedField(selected);
        setShowForm(true);
    };

    const handleFieldUpdate = (values: TemplateFieldData) => {
        const newArray = [...fields];

        if (!selectedField) {
            const fieldId = getNewId(allFields);
            newArray.push({ ...values, fieldId });
        } else {
            const fieldIndex = fields.indexOf(selectedField);
            newArray[fieldIndex] = { ...values, fieldId: selectedField.fieldId };
        }

        setFields(newArray);
    };

    const handleCloseFieldForm = () => {
        setShowForm(false);
        setSelectedField(undefined);
    };

    const handleDeleteField = (data: TemplateFieldData) => {
        const newArray = fields.filter(field => field.fieldId !== data.fieldId);

        setFields(newArray);

        handleCloseFieldForm();
    };

    return (
        <CollapsibleBlock title={title} className="template-fields-container">
            {!showForm && (
                <PlusButton size="small" onClick={handleAddClick} className="template-field-add">
                    Add
                </PlusButton>
            )}
            {showForm && (
                <FieldsForm
                    isTable={isTable}
                    data={selectedField}
                    onCloseFrom={handleCloseFieldForm}
                    onSave={handleFieldUpdate}
                    onDelete={handleDeleteField}
                    types={types}
                />
            )}
            {!!fields.length && (
                <FieldsList
                    fields={fields}
                    setFields={setFields}
                    onEditClick={handleEditClick}
                    disabled={!!selectedField}
                />
            )}
            {!fields.length && !showForm && (
                <Empty
                    description="No fields"
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    className="template-fields-empty"
                />
            )}
        </CollapsibleBlock>
    );
};

interface Props extends FieldsState {
    title: string;
    isTable: boolean;
    types: Types[] | null;
    allFields: TemplateFieldData[];
}
