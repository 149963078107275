import { Checkbox, Form, Input, Select } from 'antd';

import { IntegrationEntity, TemplateTypeData } from '../../types';
import { getIntegrationEntitiesOptions } from '../helpers';
import { CollapsibleBlock, TooltipIcon } from 'components/atomic';

export const Profile = ({ integrationEntities }: Props) => {
    const form = Form.useFormInstance();
    const id = form.getFieldValue('value');

    return (
        <CollapsibleBlock title="Document type profile">
            <div className="template-type-profile-row">
                <Form.Item<TemplateTypeData>
                    label="Document type name"
                    name="label"
                    required={false}
                    rules={[{ required: true, message: 'Please enter type name' }]}>
                    <Input autoFocus />
                </Form.Item>
                {id && (
                    <Form.Item<TemplateTypeData>
                        label="ID"
                        name="value"
                        required={false}
                        className="template-type-profile-id"
                        rules={[{ required: true, message: 'Please enter type name' }]}>
                        <Input disabled />
                    </Form.Item>
                )}
            </div>
            <div className="row">
                <Form.Item<TemplateTypeData>
                    name="isAutoExtract"
                    valuePropName="checked"
                    required={false}>
                    <Checkbox>Auto Extract</Checkbox>
                </Form.Item>
            </div>
            <div className="template-type-profile-row">
                <Form.Item<TemplateTypeData>
                    label="Integration entity action"
                    name="integrationEntityId"
                    required={false}>
                    <Select
                        options={getIntegrationEntitiesOptions(integrationEntities)}
                        placeholder="Select integration entity"
                        allowClear
                    />
                </Form.Item>
                <TooltipIcon suffix="What is this?">
                    represent the entity type in the target system
                </TooltipIcon>
            </div>
        </CollapsibleBlock>
    );
};

interface Props {
    integrationEntities: IntegrationEntity[] | null;
}
