import { DeleteAction } from './DeleteAction';
import { EditAction } from './EditAction';
import { FixAction } from './FixAction';
import { SetAction } from './SetAction';
import { ViewAction } from './ViewAction';
import { ReMatchAction } from './ReMatchAction';
import { ApproveAction } from './ApproveAction';

export const ListActions = ({
    onViewClick,
    onEditClick,
    onDeleteClick,
    onFixClick,
    onSetClick,
    onApproveClick,
    onReMatchClick,
    isTypeMatch = false,
}: ListActionsProps) => {
    return (
        <div className="list-actions">
            {onFixClick && <FixAction action={onFixClick} />}
            {!isTypeMatch && onViewClick && <ViewAction action={onViewClick} />}
            {isTypeMatch && onReMatchClick && <ReMatchAction action={onReMatchClick} />}
            {onSetClick && <SetAction action={onSetClick} />}
            {onEditClick && <EditAction action={onEditClick} />}
            {onApproveClick && <ApproveAction action={onApproveClick} />}
            {onDeleteClick && <DeleteAction action={onDeleteClick} />}
        </div>
    );
};

export interface ListActionsProps {
    onViewClick?: () => void;
    onEditClick?: () => void;
    onDeleteClick?: () => void;
    onApproveClick?: () => void;
    onFixClick?: () => void;
    onSetClick?: () => void;
    onReMatchClick?: () => void;
    isTypeMatch?: boolean;
}
