import { useData } from 'hooks/data.hooks';
import { apiRoutes } from 'services';
import { ForOmerIcon, TooltipIcon } from '../atomic';

export const About = () => {
    const { data } = useData<string>(apiRoutes.appVersion);

    return (
        <div className="about">
            <div className="version">
                DOConvert v {data} &nbsp;
                <TooltipIcon>
                    This version honors Omer Zadikevitch, symbolized by four small waves and one
                    large wave. The waves represent Omer's four children and himself, with a pulse
                    signifying that surfing and family are at the heart of life.
                </TooltipIcon>
            </div>
            <ForOmerIcon />
        </div>
    );
};
