import { Form, Select } from 'antd';

import { useData } from 'hooks/data.hooks';
import { apiRoutes } from 'services';
import { TemplateTypesResponseData } from '../TemplateTypes/types';
import { getTemplateTypesOptions } from './helpers';

export const TemplateTypeSelect = ({ onChange, isRequired, disabled = false }: Props) => {
    const { data, loading } = useData<TemplateTypesResponseData>(apiRoutes.templatesTypes);

    return (
        <Form.Item
            label="Template type"
            name="type"
            required={false}
            rules={isRequired ? [{ required: true, message: 'Please select template type' }] : []}>
            <Select
                loading={loading}
                options={getTemplateTypesOptions(data)}
                placeholder="Select template type"
                onChange={onChange}
                allowClear
                showSearch
                optionFilterProp="children"
                disabled={disabled}
                filterOption={(input, option) =>
                    (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
                }
            />
        </Form.Item>
    );
};

interface Props {
    onChange?: (value: number) => void;
    isRequired: boolean;
    disabled?: boolean;
}
