import { Alert, Button, Form } from 'antd';
import { useEffect, useState } from 'react';

import { useAppDispatch } from 'hooks/redux.hooks';
import { getListenerAction } from 'hooks/data.hooks';
import { apiRoutes } from 'services';
import { useDrawer } from 'components/DrawerContainer';
import { TemplateTypeData, TemplateTypeResponseData } from '../types';
import { Profile } from './Profile/Profile';
import { FieldsContainer } from './Fields/FieldsContainer';
import {
    prepareDataAndSave,
    prepareInitialFormData,
    separateFields,
    separateTypes,
} from './helpers';
import { useTemplateTypeData } from './useTemplateTypeData.hook';
import { CommonForm } from 'components/atomic';
import { setSelectedTypeAction } from 'state/actions';
import { DocumentTemplateForm } from '../../DocumentTemplates/DocumentTemplateForm';

export const TemplateTypeForm = ({ action, data }: Props) => {
    const initialData = prepareInitialFormData(data);
    const separatedFields = separateFields(initialData);

    const dispatch = useAppDispatch();
    const { closeDrawer } = useDrawer();
    const { fieldTypes, integrationEntities } = useTemplateTypeData();
    const separatedTypes = separateTypes(fieldTypes);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [form] = Form.useForm();
    const [generalFields, setGeneralFields] = useState(separatedFields[0]);
    const [tableFields, setTableFields] = useState(separatedFields[1]);
    const allFields = [...generalFields, ...tableFields];
    const { openDrawer } = useDrawer();

    useEffect(() => {
        if (error && allFields.length) {
            setError(false);
        }
    }, [allFields.length]);

    const handleSubmitClick = () => {
        form.submit();
    };

    const onFinish = async (values: TemplateTypeData) => {
        if (!generalFields.length || !tableFields.length) {
            return setError(true);
        }

        setLoading(true);

        const response = await prepareDataAndSave(allFields, values, action, data?.value);

        if (response) {
            dispatch(setSelectedTypeAction({ label: response.label, value: response.value }));
        }

        dispatch(getListenerAction(apiRoutes.templatesTypes));

        handleClose();

        setLoading(false);
    };

    const handleClose = () => {
        form.resetFields();
        closeDrawer();

        if (action === 'add') {
            openDrawer('New doc template', <DocumentTemplateForm />);
        }
    };

    return (
        <div className="template-types-form">
            <CommonForm
                name="templateType"
                initialValues={initialData}
                onFinish={onFinish}
                noValidate={true}
                form={form}
                submitOnEnter={false}>
                <Profile integrationEntities={integrationEntities} />
            </CommonForm>

            {error && (
                <Alert
                    className="template-fields-error"
                    message="Please add at least one field"
                    type="error"
                    showIcon
                />
            )}

            <FieldsContainer
                title="General fields"
                isTable={false}
                types={separatedTypes[0]}
                fields={generalFields}
                allFields={allFields}
                setFields={setGeneralFields}
            />

            <FieldsContainer
                title="Table columns"
                isTable={true}
                types={separatedTypes[1]}
                fields={tableFields}
                allFields={allFields}
                setFields={setTableFields}
            />

            <div className="template-types-form-controls">
                <Button
                    type="primary"
                    onClick={handleSubmitClick}
                    disabled={loading}
                    loading={loading}>
                    Save
                </Button>
                <Button type="link" disabled={loading} onClick={handleClose}>
                    Cancel
                </Button>
            </div>
        </div>
    );
};

interface Props {
    action: FormAction;
    data?: TemplateTypeResponseData;
}
