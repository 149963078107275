import { Button, Checkbox, Form, Input, Select } from 'antd';
import { useEffect, useRef } from 'react';

import { TemplateFieldData, Types } from '../../types';
import { getTypesOptions } from '../helpers';
import { CommonForm } from 'components/atomic';

export const FieldsForm = ({ data, isTable, onSave, onCloseFrom, onDelete, types }: Props) => {
    const [form] = Form.useForm();
    const scrollHelperRef = useRef<HTMLDivElement>(null);

    const onFinish = async (values: any) => {
        onSave({ ...data, ...values, isTable });

        handleClose();
    };

    const handleClose = () => {
        form.resetFields();
        onCloseFrom();
    };

    const handleDelete = () => {
        data && onDelete(data);
    };

    const scrollToForm = () => {
        if (data != null && scrollHelperRef.current) {
            scrollHelperRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    useEffect(() => {
        form.setFieldsValue(data);
        scrollToForm();
    }, [data]);

    return (
        <div ref={scrollHelperRef}>
            <CommonForm
                name={`templateFields${isTable ? 'Table' : 'General'}`}
                initialValues={data}
                onFinish={onFinish}
                noValidate={true}
                className="template-fields-form"
                form={form}>
                <Form.Item<TemplateFieldData>
                    label="Field label"
                    name="label"
                    required={false}
                    rules={[{ required: true, message: 'Please enter field label' }]}>
                    <Input autoFocus />
                </Form.Item>

                <Form.Item<TemplateFieldData>
                    label="Property name"
                    name="propertyName"
                    required={false}
                    rules={[{ required: true, message: 'Please enter property name' }]}>
                    <Input />
                </Form.Item>

                <Form.Item<TemplateFieldData> label="Field type" name="type" required={false}>
                    <Select
                        options={getTypesOptions(types)}
                        placeholder="Select field type"
                        allowClear
                    />
                </Form.Item>

                <div className="template-fields-form-checkboxes">
                    <Form.Item<TemplateFieldData>
                        name="isRequired"
                        valuePropName="checked"
                        required={false}
                        className="template-fields-form-checkboxes-required">
                        <Checkbox>Required</Checkbox>
                    </Form.Item>
                </div>

                <div className="template-fields-buttons">
                    <div>
                        <Button type="primary" size="small" htmlType="submit">
                            Save
                        </Button>
                        <Button type="link" onClick={handleClose} size="small">
                            Cancel
                        </Button>
                    </div>
                    {!!data && (
                        <Button size="small" onClick={handleDelete}>
                            Delete
                        </Button>
                    )}
                </div>
            </CommonForm>
        </div>
    );
};

interface Props {
    data?: TemplateFieldData;
    isTable: boolean;
    types: Types[] | null;
    onSave: (data: TemplateFieldData) => void;
    onCloseFrom: () => void;
    onDelete: (data: TemplateFieldData) => void;
}
