import { List } from 'antd';
import { useEffect } from 'react';

import { Container, Divider } from 'components/atomic';
import { apiRoutes } from 'services';
import { useData } from 'hooks/data.hooks';
import { TemplateTypesItem } from './TemplateTypesItem';
import { AddDocumentTypeButton } from './AddDocumentTypeButton';
import { TemplateTypesResponseData } from './types';
import { useAppDispatch, useAppSelector } from 'hooks/redux.hooks';
import { setSelectedTypeAction } from 'state/actions';

export const TemplateTypes = () => {
    const { data, loading } = useData<TemplateTypesResponseData>(apiRoutes.templatesTypes);
    const selectedType = useAppSelector(state => state.ui.selectedType);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!data) {
            return;
        }

        if (!selectedType) {
            dispatch(setSelectedTypeAction(data.content[0] || null));
        }
    }, [data, selectedType]);

    return (
        <Container className="list">
            <div className="list-header">
                <h2>Document types</h2>
            </div>
            <Divider className="list" />
            <List
                className="template-types-list"
                loading={loading}
                dataSource={data?.content}
                renderItem={item => <TemplateTypesItem data={item} />}
                rowKey={item => item.value.toString()}
                pagination={false}
            />
            <AddDocumentTypeButton type="link" />
        </Container>
    );
};
