export const apiRoutes = {
    login: 'auth/login',
    refreshToken: 'auth/refresh-token',
    userDetails: 'auth/user-details',
    users: 'users',
    overview: 'orders/overview',
    document: 'orders',
    documentPdf: 'orders/pdf',
    documents: 'orders/import',
    documentsErrors: 'orders/errors',
    documentsMatch: 'orders/match',
    documentsUpload: 'orders/templates',
    documentIds: 'orders/documentsIds',
    documentsImport: 'integration/send-data',
    documentsImportResult: 'orders/results',
    documentsDownload: 'orders/export-multiple',
    settings: 'settings',
    appVersion: 'settings/version',
    testConnection: 'integration/test-connection',
    translationsTable: 'settings/translation-table/csv',
    translationsTableDownload: 'settings/translation-table/csv/download',
    translationsTableSync: 'integration/sync-translation-table',
    integrationEntities: 'settings/integration/entities',
    addressTable: 'settings/address-table',
    userImage: 'settings/logo-icon',
    templateTypes: 'types',
    templatesTypes: 'fields/types',
    fieldsTypes: 'fields/field-types',
    templates: 'templates',
    templatesAll: 'templates/all',
    templateWithPdf: 'templates/with-pdf',
    templatesPdf: 'templates/pdf',
    templatesByType: 'templates/type',
    templatesCustomProperties: 'templates/custom-properties',
    templateSettings: 'template-settings',
    templateClone: 'clone',
    templatesApproveStatus: 'templates/approve-status',
    postProcessRules: 'process-rules',
    deletePostProcessRules: 'delete-process-rules',
    updateRules: 'update-rules',
    updatePDF: 'update-pdf',
    validations: 'validations',
    license: 'license',
    websocket: 'websocket',
};
