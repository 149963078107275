import { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, InputNumber, Select } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useDrawer } from 'components/DrawerContainer';
import { CommonForm, Container, TooltipIcon } from 'components/atomic';
import { useAppDispatch } from 'hooks/redux.hooks';
import { ImportData } from '../ImportButton/types';
import { reImportWithTemplateId } from 'services/docs.service';
import { getListenerAction, useData } from 'hooks/data.hooks';
import { addDocumentTemplateWithPDF, apiRoutes } from 'services';
import { TemplateTypeSelect } from 'components/Templates/FormItems';
import { getTemplatesOptions } from '../ImportButton/helpers';
import { TemplateCustomProperty, TemplatesDataResponse } from 'components/Templates';
import { routes } from 'navigator';
import { MatchViewer } from './MatchViewer';
import { getUsersOptions } from '../Templates/FormItems/helpers';
import { UsersDataResponse } from '../Users/types';
import { MatchData } from './types';
import { CustomDataList } from '../Template';
import { useUserData } from '../../hooks/user.hooks';

export const MatchTemplateForm = ({
    data,
    documentId,
    customDataList,
    showDocImg = true,
}: Props) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { closeDrawer } = useDrawer();
    const { data: templatesData, loading: loadingTemplates } = useData<TemplatesDataResponse>(
        apiRoutes.templates
    );
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm<MatchData>();
    const type = Form.useWatch('type', form);
    const [newTemplate, setNewTemplate] = useState(false);
    const { role } = useUserData();
    const isPermission = role && ['ROLE_ADMIN', 'ROLE_EDITOR'].includes(role);
    let templatesOptions = getTemplatesOptions(templatesData, type, isPermission);

    let custom = useData<TemplateCustomProperty[]>(
        isPermission ? apiRoutes.templatesCustomProperties : null
    ).data;

    let users = useData<UsersDataResponse>(isPermission ? apiRoutes.users : null).data;

    const handleFinish = async (values: MatchData) => {
        setLoading(true);

        if (newTemplate) {
            values.orderId = documentId;
            const template = await addDocumentTemplateWithPDF(values);

            if (template) {
                navigate(`${routes.templates}/${template.templateId}`);
            }
        } else {
            const documents = await reImportWithTemplateId(documentId, values.template);
            dispatch(getListenerAction(`${apiRoutes.document}/${documentId}`));
            if (documents) {
                navigate(`${routes.documents}/${documentId}?ids=${documents.map(doc => doc.id)}`);
            }
        }

        handleClose();
        setLoading(false);
    };

    const handleClose = () => {
        form.resetFields();
        closeDrawer();
    };

    const handleTypeChange = () => {
        form.setFieldValue('template', undefined);
    };

    const handleTemplateChange = (e: number) => {
        if (e === -1) {
            setNewTemplate(true);
        }
    };

    useEffect(() => {
        if (!custom || !customDataList?.length) {
            return;
        }

        const values: Record<string, string | boolean | number> = {};

        customDataList?.forEach(item => {
            const property = custom?.find(({ key }) => item.key === key);
            let value: string | boolean | number = item.value;

            switch (property?.type) {
                case 'boolean':
                    value = value === 'true';
                    break;
                case 'number':
                    value = parseInt(value);
                    break;
                default:
                    break;
            }

            values[item.key] = value;
        });

        form.setFieldsValue(values);
    }, [custom]);

    const initialValues: Partial<MatchData> = {
        name: '',
        ...data,
    };

    return (
        <CommonForm
            name="matchDocumentForm"
            onFinish={handleFinish}
            form={form}
            initialValues={initialValues}>
            <Container className="form-container">
                {showDocImg && <MatchViewer id={documentId} />}
                <TemplateTypeSelect onChange={handleTypeChange} isRequired={true} />
                <Form.Item<ImportData> label="select template" name="template" required={false}>
                    <Select
                        loading={loadingTemplates}
                        options={templatesOptions}
                        placeholder="Select template"
                        disabled={!type}
                        allowClear
                        showSearch
                        onChange={handleTemplateChange}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())
                        }
                    />
                </Form.Item>
                {isPermission && newTemplate && (
                    <div>
                        <Form.Item<MatchData> label="Template name" name="name" required={false}>
                            <Input autoFocus />
                        </Form.Item>
                        <Form.Item<MatchData>
                            label={
                                <span className="with-tooltip">
                                    Entity ID (Optional)
                                    <TooltipIcon>
                                        a unique identification number that represents the business
                                        entity
                                    </TooltipIcon>
                                </span>
                            }
                            name="businessEntityId"
                            required={false}>
                            <Input />
                        </Form.Item>
                        <Form.Item<MatchData>
                            label="Select owner email"
                            name="userIds"
                            required={false}>
                            <Select
                                options={getUsersOptions(users)}
                                placeholder="Select owner email"
                                mode="multiple"
                            />
                        </Form.Item>
                        {!!custom &&
                            custom.map(item => {
                                let input = <Input />;

                                switch (item.type) {
                                    case 'boolean':
                                        input = <Checkbox>{item.name}</Checkbox>;
                                        break;
                                    case 'number':
                                        input = <InputNumber />;
                                        break;
                                    default:
                                        break;
                                }

                                return (
                                    <Form.Item
                                        label={item.type === 'boolean' ? undefined : item.name}
                                        name={item.key}
                                        required={false}
                                        valuePropName={
                                            item.type === 'boolean' ? 'checked' : undefined
                                        }>
                                        {input}
                                    </Form.Item>
                                );
                            })}
                    </div>
                )}
            </Container>

            <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>
                {newTemplate ? 'Create new template' : 'Set'}
            </Button>
            <Button type="link" disabled={loading} onClick={handleClose}>
                Cancel
            </Button>
        </CommonForm>
    );
};

interface Props {
    documentId: number;
    showDocImg: boolean;
    data?: Partial<MatchData>;
    customDataList?: CustomDataList[];
}
