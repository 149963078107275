import { useData } from 'hooks/data.hooks';
import { FieldTypes, IntegrationEntity, Types } from '../types';
import { apiRoutes } from 'services';

export const useTemplateTypeData = () => {
    const { data: validations } = useData<Types[]>(apiRoutes.validations);
    const { data: integrationEntities } = useData<IntegrationEntity[]>(
        apiRoutes.integrationEntities
    );
    const { data: fieldTypes } = useData<FieldTypes[]>(apiRoutes.fieldsTypes);

    return {
        validations,
        integrationEntities,
        fieldTypes,
    };
};
